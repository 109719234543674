<template>
    <div class="purchasedVideo">
        <div @click="$router.go(-1)" class="leftArrow">
            <svg-icon iconClass="leftArrow"></svg-icon>
        </div>
        <van-tabs swipeable animated @change="changeTab" v-model="activeIndex">
            <van-tab title="视频">
                <div class="tab_content">
                    <videoList />
                </div>
            </van-tab>
            <van-tab title="短视频">
                <div class="tab_content">
                    <shortVideoList />
                </div>
            </van-tab>
            <van-tab title="AV解说">
                <div class="tab_content">
                    <avComment />
                </div>
            </van-tab>
            <van-tab title="帖子">
                <div class="tab_content">
                    <postList @showComment="showComment" @showShare="showShare" />
                </div>
            </van-tab>

        </van-tabs>
        <comment v-show="commentShow" :topicInfo="memberInfo" @commentSuccess="commentSuccess"
            :commentShow="commentShow" @commentClose="commentShow=false" />
        <share v-if="shareShow" :topicInfo="memberInfo" @shareSuccess="shareSuccess" :shareShow="shareShow"
            @shareClose="shareShow=false" />
    </div>
</template>

<script>
    import videoList from '@/views/mine/purchasedVideo/videoList';
    import shortVideoList from '@/views/mine/purchasedVideo/shortVideoList';
    import postList from '@/views/mine/purchasedVideo/postList';
    import avComment from '@/views/mine/purchasedVideo/avComment';
    import share from "@/views/widget/share";
    import comment from "@/views/widget/comment";
    export default {
        components: {
            postList,
            videoList,
            shortVideoList,
            avComment,
            share,
            comment,
        },
        name: 'purchasedVideo',
        data() {
            return {
                activeIndex: 0,
                commentShow: false,
                shareShow: false,
                memberInfo: {},
            }
        },
        created() {
            if (this.$route.query.i) {
                this.activeIndex = Number(this.$route.query.i);
            } else {
                this.activeIndex = 0;
            }
        },
        methods: {
            changeTab(index) {
                this.$router.replace({path: '/purchasedVideo', query: {i: index}})
            },
            // 显示评论
            showComment(data) {
                this.memberInfo = data.item;
                this.commentShow = true;
            },
            // 显示分享
            showShare(data) {
                this.memberInfo = data.item;
                this.shareShow = true;
            },
            commentSuccess() {
                //评论成功评论条数加一

                // this.mediaInfos[this.keyIndex].commentNum++;
                this.$refs.community.communityList[this.keyIndex].commentNum++;
            },
            shareSuccess() {
                //分享成功回调
                // this.mediaInfos[this.keyIndex].shareNum++;
                this.$refs.community.communityList[this.keyIndex].shareNum++;
            },
        }
    }
</script>

<style lang='scss' scoped>
    .purchasedVideo {
        position: relative;

        .leftArrow {
            position: absolute;
            left: 16px;
            top: 22px;
            transform: translate(0, -50%);
            font-size: 15px;
            color: $white1;
            z-index: 3;
        }

        /deep/ .van-tabs__wrap {
            background: $vermillion;
        }

        /deep/ .van-tabs__nav {
            width: 280px;
            margin-left: 50px;
            background: $vermillion;
        }

        /deep/ .van-tab__text {
            font-size: 15px;
            color: $white1;
        }

        /deep/ .van-tabs__line {
            background: $white1;
            bottom: 20px;
            width: 26px;
            // width: 50%;
        }

        /deep/ .van-tab--active {
            .van-tab__text {
                font-weight: 600;
                font-size: 18px;
            }

        }

        .tab_content {
            // padding: 0 16px;
            // overflow: auto;
            // -ms-overflow-style: none;
            height: calc(100vh - 44px);
            // background: $whiteThree;
        }
    }
</style>