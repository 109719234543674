import { render, staticRenderFns } from "./videoListsec.vue?vue&type=template&id=56d40b48&scoped=true&"
import script from "./videoListsec.vue?vue&type=script&lang=js&"
export * from "./videoListsec.vue?vue&type=script&lang=js&"
import style0 from "./videoListsec.vue?vue&type=style&index=0&id=56d40b48&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56d40b48",
  null
  
)

export default component.exports