<template>
  <!-- <div :class="['cove-label', { max: isMax }]">
    <div v-if="item.isVip">VIP</div>
    <div v-else-if="item.fansGift">福利</div>
    <svg-icon iconClass="gold" class="cove_icon" v-else-if="item.price > 0"></svg-icon>
    <div v-else-if="item.price == 0">免费</div>
  </div> -->
  <div>
    <div :class="['cove-label', { max: isMax }]" v-if="item.isVip">
      <div>VIP</div>
    </div>
    <div :class="['cove-label', { max: isMax }]" v-else-if="item.fansGift">
      <div>福利</div>
    </div>
    <div :class="['cove-label', { max: isMax }]" v-else-if="item.price > 0">
      <svg-icon iconClass="gold" class="cove_icon"></svg-icon>
    </div>
    <div v-else-if="item.price == 0"></div>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      default: {
        price: 180,
      },
    },
    isMax: {
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped>
.cove-label {
  background: #f21313;
  color: #fff;
  width: 33px;
  height: 16px;
  border-bottom-left-radius: 6px;
  border-top-right-radius: 6px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;

  .cove_icon {
    font-size: 14px;
  }
}
.max {
  width: 50px;
  height: 24px;
  font-size: 16px;
  .cove_icon {
    font-size: 20px;
  }
}
</style>
