import axios from "@/utils/request";

// 查询已购列表
export function queryBoughtList(data) {
    return axios.post(`/media/bought/list`, data);
}

// 查询已购AV解说
export function queryAvcomment(data) {
    return axios.post('/avcomment/buyList', data)
}

// 查询楼凤购买记录
export function queryLfBought(data) {
    return axios.post(`/loufeng/bought/list`, data);
}