<template>
    <div class="blogger-orgin">
        <Pullrefresh @onLoad="onLoad" :loading="loading" :finished="finished" @onRefresh="onRefresh" :refreshing="refreshing" :isNoData="isNoData" :firstLoading="firstLoading">
        <div class="blogger-orgin-box">
            <div class="blogger-orgin-item" v-for="(item,index) in mediaInfos" :key="index" @click="onJumpVideo(item, index)">
            <div class="cover-box">
                <ImgDecypt :src="item.videoCover || item.picList[0]" class="blogger-orgin-cover" />
                <div class="video-info-timers flex-between">
                <div class="left">
                    <span class="left-play"></span>{{item.watchTimes|watchCount}}
                </div>
                <div class="right">{{item.playTime|videotime}}</div>
                </div>
            </div>
            <h2>{{item.content}}</h2>
            <CoveLabel class="cust_label" :item="item" />
            </div>
        </div>
        </Pullrefresh>
    </div>
</template>
<script>
import { queryBoughtList } from '@/api/mine/bought.js';
import Pullrefresh from "@/views/widget/PullRefresh.vue";
import CoveLabel from "@/components/CoveLabel/index.vue";
// import { jumpVideo } from "@/utils/index.js";
export default {
    components: {
        Pullrefresh,
        CoveLabel,
    },
    data() {
        return {
        loading: false, //下滑loading
        finished: false, //数据是否加载完毕
        refreshing: false, //下拉下载loading
        isNoData: false, //数据是否为空
        firstLoading: true, //是否第一次加载
        // umt 1：原创 2:动态视频 3：粉丝福利 4:动态帖子 0:无效的占位符
        req: { bType: 2, pageNum: 1, pageSize: 10 },
        mediaInfos: [],
        };
    },
    created() {
        this.getData();
    },
    methods: {
        onLoad() {
            this.req.pageNum++;
            this.getData();
        },
    onRefresh() {
        this.refreshing = true;
        this.finished = false;
        this.req.pageNum = 1;
        this.mediaInfos = [];
        this.getData();
    },
    async getData() {
        this.loading = true;
        let req = this.req;
        let ret = await this.$Api(queryBoughtList, req);
        this.loading = false;
        this.refreshing = false;
        this.firstLoading = false;

        if (ret.code == 200 && ret.data && ret.data.mediaInfos) {
            let mediaInfos = ret.data.mediaInfos;
            this.mediaInfos = this.mediaInfos.concat(mediaInfos);
            if (this.mediaInfos.length > 0) {
            this.author = this.mediaInfos[0].author;
            }
            if (mediaInfos.length == 0 && this.req.pageNum == 1) {
            this.isNoData = true;
            return;
            }
            if (mediaInfos.length < this.req.pageSize) {
            this.finished = true;
            }
        }
        },
    onJumpVideo(item, index) {
      // if (item.videoUrl) {
        this.$store.commit('video/SET_COMMUNITY_LIST', {list: this.mediaInfos, isRecommend: false})
        this.$store.commit('video/UPDATE_COMMUNITY_INDEX', index)
        let routeData = {
          path: "/bloggerShortVideo",
          query: {},
        };
        if (this.fromPath) {
          routeData.query.fromPath = this.fromPath;
        }
        this.$router.push(routeData);
      // } else {
      //   let data = {
      //     imgs: item.picList,
      //     index: 0,
      //     info: item,
      //     uid: item.author.id
      //   }
      //   let dataStr = encodeURI(JSON.stringify(data))
      //   this.$router.push({
      //     name: "picturePreview",
      //     query: {
      //       data: this.encodeBase64(dataStr)
      //     },
      //   });
      // }
    },
    // 转base64
    // encodeBase64(str) {
    //   let base64Str = Buffer.from(str, 'utf-8').toString('base64');
    //   return base64Str;
    // },
  },
};
</script>
<style lang="scss" scoped>
.blogger-orgin {
  box-sizing: border-box;
  width: 100%;
  /deep/ .van-grid-item__content {
    padding: 0;
  }
  .blogger-orgin-box {
    display: grid;
    padding: 10px;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
    box-sizing: border-box;
    .blogger-orgin-item {
      border-radius: 6px;
      position: relative;
      .blogger-orgin-cover {
        width: 100%;
        height: 250px;
        overflow: hidden;
        border-radius: 6px;
        /deep/ img {
          border-radius: 6px;
          overflow: hidden;
        }
      }
      h2 {
        width: 168px;
        padding: 5px 0 10px 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 14px;
        color: #515151;
      }
    }
  }
  //图标
  .cust_label {
    position: absolute;
    top: 0;
    right: 0;
  }

  //播放次数 时间
  .cover-box {
    position: relative;
  }
  .video-info-timers {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 26px;
    align-items: center;
    background: linear-gradient(
        to bottom,rgba(0, 0, 0, 0),rgba(0, 0, 0, 0.2)
    );
    padding: 4px 8px;
    box-sizing: border-box;
    font-size: 10px;
    color: #ffffff;
    .left {
        display: flex;
        align-items: center;
        .left-play {
            display: inline-block;
            margin-right: 3px;
            width: 10px;
            height: 10px;
            background: url("../../../../assets/png/play_icon.png") center center no-repeat;
            background-size: 100%;
        }
    }
    }
}
</style>