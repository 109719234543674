<template>
    <div class="videoPage">
        <Pullrefresh @onLoad="onLoad" :loading="loading" :finished="finished" @onRefresh="onRefresh"
            :refreshing="refreshing" :firstLoading="firstLoading" :isNoData="isNoData" :isHigehtMax="true">
            <div class="dynamic" v-for="(item, index) in videoList" :key="index">
                <div class="userInfo">
                    <ImgDecypt class="author" :round="true" :src="item.author.avatarUrl" :key="item.id"
                        @click.native="jumpUserDetail(item)" />
                    <div class="info">
                        <div class="name">{{item.author.nickName}}</div>
                        <div class="type" v-if="item.isTop">置顶</div>
                        <div class="focusOn">
                            <div class="focusOnBtn"
                                v-show="!(item.author&&item.author.cared) && item.author.id != userInfo.id"
                                @click="userCare(item)">+ 关注</div>
                        </div>
                    </div>
                </div>
                <div class="publishContent">{{item.content}}</div>
                <div v-if="!item.repostMediaInfo||(item.repostMediaInfo&&item.repostMediaInfo.mType==1)">
                    <div class="publishType" v-for="(tagItem,tagIndex) in item.topics" :key="tagIndex">{{tagItem}}</div>
                </div>
                <div v-else>
                    <div class="publishType" v-for="(tagItem,tagIndex) in item.repostMediaInfo.flags" :key="tagIndex">
                        {{tagItem}}</div>
                </div>
                <van-grid column-num="3" :border="false"
                    v-if="!item.repostMediaInfo||(item.repostMediaInfo&&item.repostMediaInfo.mType==1)">
                    <van-grid-item class="gridBox" use-slot v-for="(x, y) in item.picList" :key="'img' + y">
                        <div class="imgBox" v-if="y <= 5" @click="showBigImg(item, index)">
                            <div class="mask" v-if="y == 5 && item.picList.length - 6 > 0">
                                <div>+<span>{{item.picList.length - 6}}</span></div>
                            </div>
                            <ImgDecypt class="publishImg" :src="x" :key="'img ' + y" />
                        </div>

                    </van-grid-item>
                </van-grid>
                <div class="video" v-else>
                    <div tag="div" @click="jumpUserDetail(item.repostMediaInfo)" class="o-name">
                        @{{item.repostMediaInfo&&item.repostMediaInfo.author.nickName}}</div>
                    <div class="content">{{item.repostMediaInfo&&item.repostMediaInfo.content}}</div>
                    <div class="o-img" @click="goFullPlay(item)">
                        <ImgDecypt class="o-imgshow" :src="item.repostMediaInfo&&item.repostMediaInfo.videoCover" />
                        <div class="video-info flex-between">
                            <div class="video-left"><span
                                    class="play"></span>{{item.repostMediaInfo&&item.repostMediaInfo.watchTimes|watchCount}}
                            </div>
                            <div class="video-right">{{item.repostMediaInfo&&item.repostMediaInfo.playTime|videotime}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="actionBar">
                    <div class="actionBtn" @click="commentS(item, index)">
                        <div class="comment"></div><span>{{item.commentNum|watchCount}}</span>
                    </div>
                    <div class="actionBtn" @click="like(item)">
                        <div class="like" :class="{isLike: item.bLike}"></div><span>{{item.likeNum|watchCount}}</span>
                    </div>
                    <div class="actionBtn" @click="shareS(item, index)">
                        <div class="share"></div><span>{{item.shareNum|watchCount}}</span>
                    </div>
                </div>
            </div>
        </Pullrefresh>
    </div>
</template>

<script>
    import Pullrefresh from "@/views/widget/PullRefresh.vue";
    // import videoListsec from '@/views/widget/videoListsec.vue';
    import {
        queryBoughtList
    } from '@/api/mine/bought.js';
    import {
        jumpVideo
    } from "@/utils/index.js";
    import {
        userCare,
        like
    } from "@/api/app.js";
    import {
        mapGetters
    } from "vuex";
    export default {
        components: {
            Pullrefresh,
            // videoListsec,
        },
        computed: {
            ...mapGetters({
                userInfo: "userInfo",
            }),
        },
        data() {
            return {
                loading: false,
                finished: false,
                refreshing: false,
                isNoData: false,
                firstLoading: true, //是否第一次加载
                pageNum: 1,
                pageSize: 10,
                videoList: []
            }
        },
        created() {
            this.getData();
        },
        methods: {
            //上滑加载
            onLoad() {
                this.pageNum++;
                this.getData();
            },
            //刷新
            onRefresh() {
                this.refreshing = true;
                this.finished = false;
                this.pageNum = 1;
                this.videoList = [];
                this.getData();
            },
            //获取数据
            async getData() {
                this.loading = true;
                let req = {
                    bType: 5,
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                }
                let ret = await this.$Api(queryBoughtList, req);

                this.loading = false;
                this.refreshing = false;
                this.firstLoading = false;

                if (ret && ret.code == 200) {
                    let list = ret.data.mediaInfos;
                    this.videoList = this.videoList.concat(list);

                    if (list.length == 0 && this.pageNum == 1) {
                        this.isNoData = true;
                        return;
                    }
                    if (list.length < this.pageSize) {
                        this.finished = true;
                    }
                }
            },
            // 跳转到详情页
            jumpUserDetail(item) {
                // item 4博主 1用户
                if (item.author.type == 4) {
                    this.$router.push({
                        path: "/bloggerDetails",
                        query: {
                            id: item.author.id,
                        },
                    });
                    return;
                }
                if (item.author.type == 1) {
                    this.$router.push({
                        path: "/userDetails",
                        query: {
                            id: item.author.id,
                        },
                    });
                    return;
                }
            },
            // 关注
            async userCare(item) {
                let req = {
                    isCare: true, //true为关注，false为取消关注
                    userId: item.author.id,
                };
                // console.log(item.author.id)
                let ret = await this.$Api(userCare, req);
                if (ret.code == 200) {
                    this.$toast("关注成功");
                    // this.communityList.forEach(i => {
                    //     if (i.author.id == item.author.id) {
                    //         i.author.cared = true;
                    //     }
                    // })
                    item.author.cared = true;
                }
            },
            //点赞
            async like(item) {
                let req = {
                    isLike: !item.bLike, //true为点赞，false为取消点赞
                    mediaId: item.id,
                };
                let ret = await this.$Api(like, req);
                if (ret.code == 200) {
                    if (!item.bLike) {
                        //如果prop为false 就是点赞
                        this.$toast("点赞成功");
                        item.bLike = true;
                        item.likeNum++;
                    } else {
                        //取消点赞
                        item.bLike = false;
                        item.likeNum--;
                    }
                } else {
                    this.$toast(ret.tip);
                }
            },
            // 监听图片点击事件
            showBigImg(item, index) {
                let data = {
                    imgs: item.picList,
                    index: index,
                    info: item,
                    uid: item.author.id
                }
                let dataStr = encodeURI(JSON.stringify(data))
                this.$router.push({
                    name: "picturePreview",
                    query: {
                        data: this.encodeBase64(dataStr)
                    },
                });
                // let isVip = this.userInfo.vipExpire * 1000 > new Date().getTime();
                // if (isVip) {
                //     this.$emit('showImg', item.picList)
                // } else {
                //     this.$emit('showMembership', item)
                // }
            },
            // 转base64
            encodeBase64(str) {
                let base64Str = Buffer.from(str, 'utf-8').toString('base64');
                return base64Str;
            },
            //监听分享点击事件
            shareS(item, index) {
                this.$emit('showShare', {
                    item: item,
                    index: index
                })
            },

            //监听评论点击事件
            commentS(item, index) {
                this.$emit('showComment', {
                    item: item,
                    index: index
                })
            },
            goFullPlay(item) {
                jumpVideo(item);
            },
        }

    }
</script>

<style lang='scss' scoped>
    .videoPage {
        // margin-top: 10px;
        padding: 10px 10px 0;
        height: calc(100vh - 44px);

        .video-cover {
            margin-top: 0;
            margin-bottom: 10px;
        }

        .dynamic {
            // margin: 0 16px;
            border-bottom: 1px solid $paleGrey;
        }

        .userInfo {
            display: flex;
            margin-top: 20px;
            padding: 0 10px;

            .author {
                width: 41px;
                height: 41px;
                border-radius: 50%;
                margin-right: 13px;
            }

            .name {
                // margin-top: 3px;
                margin-right: 15px;
            }

            .type {
                // margin-top: 3px;
                width: 30px;
                height: 16px;
                font-size: 10px;
                border-radius: 4px;
                color: $white1;
                text-align: center;
                line-height: 16px;
                background-image: linear-gradient(to right, $tomato 0%, $macaroniAndCheese 100%);
            }

            .info {
                flex: 2;
                display: flex;
                align-items: center;
                // justify-content: space-between;
                height: 24px;

                .focusOn {
                    flex: 2;
                    display: flex;
                    justify-content: end;

                    .focusOnBtn {
                        // flex:2;
                        width: 64px;
                        height: 24px;
                        font-size: 11px;
                        border-radius: 18px;
                        line-height: 24px;
                        text-align: center;
                        background: $vermillion;
                        color: $beigeTwo;

                    }
                }

            }


        }

        .publishContent {
            margin: 10px 10px 13px;
            color: $greyishBrownThree;
            font-size: 11px;
        }

        .publishType {
            margin: 0 10px 10px;
            height: 22px;
            line-height: 22px;
            padding: 0 5px;
            // box-sizing: border-box;
            border: 1px solid $cherryRedTwo;
            color: $vermillion;
            display: inline-block;
            border-radius: 9px;
        }

        .van-grid {
            margin: 0 10px;
        }

        .gridBox:nth-child(n+7) {
            display: none;
        }

        .imgBox {
            width: 110px;
            height: 110px;
            position: relative;

            .mask {
                width: 100%;
                height: 100%;
                position: absolute;
                background: rgba(0, 0, 0, 0.4);
                text-align: center;
                color: $white1;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 20px;
                z-index: 1;
            }

            .publishImg {
                width: 100%;
                height: 100%;
                border-radius: 4px;
            }

        }

        /deep/ .van-grid-item__content {
            padding: 2.5px 0;
            // padding: 0;
        }

        .actionBar {
            padding: 0 24px;
            margin: 10px 0;
            font-size: 13px;
            color: $greyishBrown;
            display: flex;
            justify-content: space-between;

            .actionBtn {
                display: flex;
                align-items: center;

                .comment {
                    margin-right: 6px;
                    width: 18px;
                    height: 18px;
                    background-image: url('../../../../assets/png/comment.png');
                    background-size: 100% 100%;
                }

                .like {
                    margin-right: 6px;
                    width: 18px;
                    height: 18px;
                    background-image: url('../../../../assets/png/zan.png');
                    background-size: 100% 100%;
                }

                .isLike {
                    background: url("../../../../assets/png/zaned.png") center center no-repeat !important;
                    background-size: 100% 100% !important;
                }

                .share {
                    margin-right: 6px;
                    width: 18px;
                    height: 18px;
                    background-image: url('../../../../assets/png/share.png');
                    background-size: 100% 100%;
                }
            }

        }
    }
</style>