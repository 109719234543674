<template>
    <div class="avCommentPage">
        <Pullrefresh @onLoad="onLoad" :loading="loading" :finished="finished" @onRefresh="onRefresh"
            :refreshing="refreshing" :firstLoading="firstLoading" :isNoData="isNoData" :isHigehtMax="true">
            <div class="avComment" v-for="(item, index) in avCommentList" :key="index" @click="jumpAv(item)">
                <h2>第<span class="av-item-date">{{fomtPriod(item.period)}}</span>期&nbsp;&nbsp;{{fomtDate(item)}}</h2>
                <div class="cardBox">
                    <ImgDecypt :src="item.cover" class="avImg" />
                    <div class="infoBox">
                        <div class="title">{{item.title}}</div>
                        <div class="content">{{item.desc}}</div>
                        <div class="bottom_box">
                            <div class="label">
                                <div v-for="(x, y) in item.tags" :key="y">{{x}}</div>
                            </div>
                            <div class="btn"><span>查看</span>
                                <svg-icon iconClass="rightArrow"></svg-icon>
                            </div>
                        </div>
                    </div>
                    <div class="type" v-if="item.price">
                        <svg-icon iconClass="gold"></svg-icon>
                    </div>
                </div>
            </div>
        </Pullrefresh>
    </div>
</template>

<script>
    import Pullrefresh from "@/views/widget/PullRefresh.vue";
    import {
        queryAvcomment
    } from '@/api/mine/bought.js';
    export default {
        components: {
            Pullrefresh,
        },
        data() {
            return {
                loading: false,
                finished: false,
                refreshing: false,
                isNoData: false,
                firstLoading: true, //是否第一次加载
                pageNum: 1,
                pageSize: 10,
                avCommentList: [],
            }
        },
        created() {
            this.getData();
        },
        methods: {
            //上滑加载
            onLoad() {
                this.pageNum++;
                this.getData();
            },
            //刷新
            onRefresh() {
                this.refreshing = true;
                this.finished = false;
                this.pageNum = 1;
                this.avCommentList = [];
                this.getData();
            },
            //获取数据
            async getData() {
                this.loading = true;
                let req = {
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                    // type: 'avcomment',
                }
                let ret = await this.$Api(queryAvcomment, req);

                this.loading = false;
                this.refreshing = false;
                this.firstLoading = false;

                if (ret && ret.code == 200) {
                    let list = ret.data.list ? ret.data.list : [];
                    this.avCommentList = this.avCommentList.concat(list);

                    if (list.length == 0 && this.pageNum == 1) {
                        this.isNoData = true;
                        return;
                    }
                    if (list.length < this.pageSize) {
                        this.finished = true;
                    }
                }
            },
            jumpAv(item) {
                this.$router.push({
                    path: "/avDetails",
                    query: {
                        id: item.id
                    }
                });
            },
            //格式化日期
            fomtPriod(period) {
                let str = period;
                if (period < 10) {
                    str = "0" + String(period);
                }
                return str;
            },
            fomtDate(data) {
                let createdAt = new Date(data.createdAt);
                let month = createdAt.getMonth() + 1;
                let day = createdAt.getDate();
                return month + "月" + day + "日";
            },
        }
    }
</script>

<style lang='scss' scoped>
    .avCommentPage {
        height: calc(100vh - 44px);

        .avComment {
            // margin-top: 20px;
            // padding: 10px 10px;

            h2 {
                text-align: center;
                font-size: 16px;
                color: #000;
                padding: 12px 0;

                .av-item-date {
                    background-image: linear-gradient(to right, #ff0000, #f21212);
                    color: #fff;
                    font-size: 14px;
                    margin: 0 2px;
                    padding: 2px;
                    display: inline-block;
                    border-radius: 4px;
                    font-weight: 400;
                }
            }

            .cardBox {
                height: 280px;
                box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
                border-radius: 6px;
                margin-bottom: 10px;
                overflow: hidden;
                position: relative;

                .type {
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 33px;
                    height: 16px;
                    background: $vermillion;
                    border-radius: 0 4px 0 4px;
                    text-align: center;
                    line-height: 16px;
                }

                .avImg {
                    width: 100%;
                    height: 150px;
                }

                .infoBox {
                    height: 130px;
                    padding: 10px 0 14px;
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    .title {
                        margin: 0 20px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        font-size: 16px;
                        font-weight: 600;
                        color: $purpleBrownTwo;
                    }

                    .content {
                        flex: 2;
                        margin: 10px 20px 0;
                        font-size: 13px;
                        line-height: 18px;
                        color: $grey;
                        overflow: hidden;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        display: -webkit-box;
                    }

                    .bottom_box {
                        margin: 0 10px 0 20px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        .label {
                            display: flex;

                            div {
                                padding: 0 6px;
                                height: 16px;
                                border-radius: 6px;
                                background: $vermillion;
                                font-size: 10px;
                                color: $white1;
                                line-height: 16px;
                                margin-right: 20px;
                                box-shadow: 0 3px 4px $cherryRed;
                            }
                        }

                        .btn {
                            color: $vermillion;
                            font-size: 11px;
                        }
                    }
                }

            }
        }
    }
</style>