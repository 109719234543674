<template>
  <div class="PullRefresh">
    <div class="maxLoading" :style="[isHigehtMax ? myRefresh :{ height: heightc}]" v-if="firstLoadingc || refreshingc">
      <img class="loading" src="@/assets/gif/loading.gif">
    </div>

    <van-pull-refresh v-model="refreshingc" success-text="刷新成功" @refresh="onRefresh" :disabled="disabled" :class="{'my-refresh':isHigehtMax}" v-if="!firstLoadingc && !refreshingc">
      <van-list v-model="loadingc" :finished="finished" :immediate-check="false" finished-text="没有更多数据了" @load="onLoad" :offset="10" v-if=" !firstLoadingc">
        <slot></slot>
        <!-- 下拉加载中 -->
        <template #loading>
          <div class="down-loading">
            <img class="loading" src="@/assets/gif/loading.gif" v-if="!firstLoadingc && !refreshingc">
          </div>
        </template>
      </van-list>
      <NoData v-if="isNoDatac" class="noData"></NoData>
    </van-pull-refresh>
  </div>
</template>
<script>
import NoData from "@/components/NoData";
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false, //是否禁止下拉刷新 默认为false
    },
    refreshing: {
      type: Boolean,
      default: false, //下拉刷新loading
    },
    loading: {
      type: Boolean,
      default: false, //上滑刷新loading
    },
    finished: {
      type: Boolean,
      default: false, //列表数据是否记载完毕
    },
    isNoData: {
      type: Boolean,
      default: false, //数据是否为空
    },
    firstLoading: {
      type: Boolean, //是否第一次加载
      default: false,
    },
    height: {
      type: String,
      default: "120px", //下拉loading 高度
    },
    isHigehtMax: {
      /**
       * 占满父级100%
       * ***************************注意******************************
       * 组件父级若是没有设置高度，不能设置为true，否则高度无限，将一直调用上滑加载接口
       */
      type: Boolean,
      default: false,
    },
  },
  components: {
    NoData,
  },
  data() {
    return {
      myRefresh: {
        width: "100%",
        height: "100%",
        "overflow-y": "auto",
      },
    };
  },
  computed: {
    loadingc: {
      get() {
        return this.loading;
      },
      set() {
        return this.loading;
      },
    },
    finishedc: {
      get() {
        return this.finished;
      },
      set() {
        return this.finished;
      },
    },
    refreshingc: {
      get() {
        return this.refreshing;
      },
      set() {
        return this.refreshing;
      },
    },
    isNoDatac: {
      get() {
        return this.isNoData;
      },
      set() {
        return this.isNoData;
      },
    },
    firstLoadingc: {
      get() {
        return this.firstLoading;
      },
      set() {
        return this.firstLoading;
      },
    },
    heightc: {
      get() {
        return this.height;
      },
      set() {
        return this.height;
      },
    },
  },
  methods: {
    onRefresh() {
      this.$emit("onRefresh");
    },
    onLoad() {
      this.$emit("onLoad");
    },
  },
};
</script>
<style lang="scss" scoped>
.loading {
  width: 25px;
  height: 25px;
}
/deep/ .van-pull-refresh__head {
  display: flex;
  justify-content: center;
  align-items: center;
}

/deep/ .van-loading {
  margin-top: 0;
}
.PullRefresh {
  width: 100%;
  height: 100%;
}
.my-refresh {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.maxLoading {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading_box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
